<template>
  <Dashboard v-if="isPrincipal || isRetailer" />
  <Landing v-else />
</template>

<script>
import { ref } from 'vue'

import Dashboard from '@/components/pages/Dashboard'
import Landing from '@/components/pages/Landing'

import store from '@/store'

export default {
  components: {
    Landing,
    Dashboard
  },
  setup() {
    const isPrincipal = ref(store?.state?.accountType?.principal)
    const isRetailer = ref(store?.state?.accountType?.retailer)

    return { isPrincipal, isRetailer }
  }
}
</script>
