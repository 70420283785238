<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader breadcrumb="Home" title="Dashboard" />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Export"
        size="lg"
        outline
        :disabled="isLoading"
        @click="exportTable"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_export')" width="30" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <div class="col q-col-gutter-md">
      <div>
        <FilterCard>
          <q-form ref="filterForm" @submit="onFilter">
            <div class="row items-start q-col-gutter-md">
              <div class="col-xs-12 col-sm-12 col-md-2">
                <FormDate
                  label="Month"
                  mask="####/##"
                  v-model="monthYear"
                  defaultView="YearMonth"
                  :value="monthYear"
                  :rules="[VALIDATION.required]"
                  @input="onSelectDate"
                  tabindex="1"
                />
              </div>

              <div
                class="flex justify-end gap-4 col-xs-12 col-sm-12 col-md-10 text-right"
              >
                <Button
                  variant="primary"
                  label="Filter"
                  icon="filter_alt"
                  :loading="isLoading"
                  :disabled="isLoading"
                  type="submit"
                  tabindex="6"
                />
              </div>
            </div>
          </q-form>
        </FilterCard>
      </div>

      <div class="row items-start q-col-gutter-md">
        <div
          class="col-xs-12 col-sm-6 col-md-3 col-lg-2"
          v-for="list in dashboardData"
          :key="list?.name"
        >
          <DashboardCard
            :variant="list?.variant"
            :title="list?.name"
            :subTitle="list?.subTitle"
            :value="list?.value"
            :subValue="list?.subValue"
            :arrow="list?.arrow"
            :loading="loadingMonthlySalesSummary"
          />
        </div>
      </div>

      <div class="row items-start q-col-gutter-md">
        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard
            header="By Month Sales YTD"
            :loading="loadingMonthlySales"
            :noData="!monthlySalesData"
          >
            <TrendLineChart axis="xy" :data="monthlySalesData" />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard
            header="By Month Customers (P30) YTD"
            :loading="loadingMonthlySales"
            :noData="!monthlyCustomerP30Data"
          >
            <TrendLineChart axis="xy" :data="monthlyCustomerP30Data" />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard
            header="By Month Customers (P90) YTD"
            :loading="loadingMonthlySales"
            :noData="!monthlyCustomerP90Data"
          >
            <TrendLineChart axis="xy" :data="monthlyCustomerP90Data" />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard
            header="By Month Average Basket Size"
            :loading="loadingMonthlySales"
            :noData="!monthlyBasketSizeData"
          >
            <TrendLineChart axis="xy" :data="monthlyBasketSizeData" />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard
            header="By Month Average Frequency"
            :loading="loadingMonthlySales"
            :noData="!monthlyFrequencyData"
          >
            <TrendLineChart axis="xy" :data="monthlyFrequencyData" />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard
            header="By Month Lines per Basket"
            :loading="loadingMonthlySales"
            :noData="!monthlyBasketLinesData"
          >
            <TrendLineChart axis="xy" :data="monthlyBasketLinesData" />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard
            header="Top Category Sales (Top 5)"
            :loading="loadingCategory"
            :noData="!categoryData"
          >
            <HorizontalBarChart axis="xy" :data="categoryData" />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard
            header="Top Brand Sales (Top 5)"
            :loading="loadingBrand"
            :noData="!brandData"
          >
            <HorizontalBarChart axis="xy" :data="brandData" />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard
            header="Top SKU Sales (Top 10)"
            :loading="loadingSKU"
            :noData="!SKUData"
          >
            <HorizontalBarChart axis="xy" :data="SKUData" />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard
            header="Top Store Sales (Top 10)"
            :loading="loadingStore"
            :noData="!storeData"
          >
            <HorizontalBarChart axis="xy" :data="storeData" />
          </SimpleCard>
        </div>
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    :footer="false"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <div class="p-4">
      <h5 class="q-mb-md">Dashboard</h5>
      <p>
        {{ HELP.DASHBOARD.description }}
      </p>

      <q-separator class="q-my-md" />

      <h6 class="q-mb-md text-sm">Definition of Terms</h6>
      <Table
        style="max-height: 50vh"
        :rows="HELP.DASHBOARD.terms"
        :columns="HELP.TABLE"
        :limit="0"
        hide-bottom
      >
        <template v-slot:body="props">
          <q-tr>
            <q-td>
              <strong>{{ props.row.name }}</strong>
            </q-td>
            <q-td :style="{ whiteSpace: 'normal' }">
              {{ props.row.description }}
            </q-td>
          </q-tr>
        </template>
      </Table>
    </div>
  </Modal>
</template>

<script>
import { ref, inject, watch, onBeforeMount, computed } from 'vue'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import { FilterCard, SimpleCard, DashboardCard } from '@/components/cards'
import { FormDate } from '@/components/inputs'
import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { TrendLineChart, HorizontalBarChart } from '@/components/charts'
import Table from '@/components/Table'

import {
  FORMAT,
  DATE,
  VALIDATION,
  getIconUrl,
  Toast,
  fetchData,
  EXPORT
} from '@/tools'
import { HELP, dashboardWidgets } from '@/constants'

export default {
  components: {
    PageHeader,
    PageContent,
    FilterCard,
    SimpleCard,
    DashboardCard,
    FormDate,
    Button,
    Modal,
    TrendLineChart,
    HorizontalBarChart,
    Table
  },
  setup() {
    const state = inject('store')?.state
    const { showToast } = Toast()

    const {
      data: dataMonthlySalesSummary,
      error: errorMonthlySalesSummary,
      loading: loadingMonthlySalesSummary,
      post: postMonthlySalesSummary
    } = fetchData()

    const {
      data: dataMonthlySales,
      error: errorMonthlySales,
      loading: loadingMonthlySales,
      post: postMonthlySales
    } = fetchData()

    const {
      data: dataCategory,
      error: errorCategory,
      loading: loadingCategory,
      post: postCategory
    } = fetchData()

    const {
      data: dataBrand,
      error: errorBrand,
      loading: loadingBrand,
      post: postBrand
    } = fetchData()

    const {
      data: dataSKU,
      error: errorSKU,
      loading: loadingSKU,
      post: postSKU
    } = fetchData()

    const {
      data: dataStore,
      error: errorStore,
      loading: loadingStore,
      post: postStore
    } = fetchData()

    const isRetailer = ref(state?.accountType?.retailer)
    const showModal = ref(false)
    const filterForm = ref(null)
    const isLoading = computed(() => {
      return (
        loadingMonthlySalesSummary.value ||
        loadingMonthlySales.value ||
        loadingCategory.value ||
        loadingBrand.value ||
        loadingSKU.value ||
        loadingStore.value
      )
    })

    const monthYear = ref(
      DATE.toFriendlyMonthYear(DATE.subtractDays(new Date(), 1, 'months'), '/')
    )
    const monthlySalesData = ref(null)
    const monthlyCustomerP30Data = ref(null)
    const monthlyCustomerP90Data = ref(null)
    const monthlyBasketSizeData = ref(null)
    const monthlyFrequencyData = ref(null)
    const monthlyBasketLinesData = ref(null)
    const categoryData = ref(null)
    const brandData = ref(null)
    const SKUData = ref(null)
    const storeData = ref(null)
    const dashboardData = ref(dashboardWidgets)

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const onSelectDate = data => {
      monthYear.value = data
    }

    const onFilter = () => {
      filterForm.value.validate().then(success => {
        if (success) {
          getData()
        }
      })
    }

    const exportTable = () => {
      EXPORT.exportDashboardToExcel({
        widget: dashboardData.value,
        sales: monthlySalesData.value,
        customersP30: monthlyCustomerP30Data.value,
        customersP90: monthlyCustomerP90Data.value,
        basketSize: monthlyBasketSizeData.value,
        frequency: monthlyFrequencyData.value,
        basketLines: monthlyBasketLinesData.value,
        categorySales: categoryData.value,
        brandSales: brandData.value,
        SKUSales: SKUData.value,
        storeSales: storeData.value
      })
    }

    const getMonthlySales = () => {
      const payload = {
        filters: {
          month_year: DATE.toFriendlyDate(
            DATE.toFriendlyFirstDay(monthYear.value)
          ),
          store: 0
        }
      }

      if (isRetailer.value) {
        payload.filters.principal = state?.defaultID

        postMonthlySalesSummary(
          '/v1/retailer/sales/monthly-sales/summary',
          payload
        )
        postMonthlySales('/v1/retailer/sales/monthly-sales', payload)
      } else {
        payload.filters.retailer = state?.defaultID

        postMonthlySalesSummary('/v1/sales/monthly-sales/summary', payload)
        postMonthlySales('/v1/sales/monthly-sales', payload)
      }
    }

    const getCategories = () => {
      const payload = {
        filters: {
          month_year: DATE.toFriendlyDate(
            DATE.toFriendlyFirstDay(monthYear.value)
          ),
          store: 0,
          sort_by: 'sales',
          compare: 'Year',
          limit: 5
        }
      }

      if (isRetailer.value) {
        payload.filters.principal = state?.defaultID

        postCategory('/v1/retailer/product/by-category-report', payload)
      } else {
        payload.filters.retailer = state?.defaultID

        postCategory('/v1/product/by-category-report', payload)
      }
    }

    const getBrands = () => {
      const payload = {
        filters: {
          month_year: DATE.toFriendlyDate(
            DATE.toFriendlyFirstDay(monthYear.value)
          ),
          store: 0,
          sort_by: 'sales',
          compare: 'Year',
          limit: 5
        }
      }

      if (isRetailer.value) {
        payload.filters.principal = state?.defaultID

        postBrand('/v1/retailer/product/by-brand-report', payload)
      } else {
        payload.filters.retailer = state?.defaultID

        postBrand('/v1/product/by-brand-report', payload)
      }
    }

    const getSKUs = () => {
      const payload = {
        filters: {
          month_year: DATE.toFriendlyDate(
            DATE.toFriendlyFirstDay(monthYear.value)
          ),
          store: 0,
          sort_by: 'sales',
          limit: 10
        }
      }

      if (isRetailer.value) {
        payload.filters.principal = state?.defaultID

        postSKU('/v1/retailer/product/analysis', payload)
      } else {
        payload.filters.retailer = state?.defaultID

        postSKU('/v1/product/analysis', payload)
      }
    }

    const getStoreSales = () => {
      const payload = {
        filters: {
          month_year: DATE.toFriendlyDate(
            DATE.toFriendlyFirstDay(monthYear.value)
          ),
          sort_by: 'sales',
          limit: 10
        }
      }

      if (isRetailer.value) {
        payload.filters.principal = state?.defaultID

        postStore('/v1/retailer/sales/monthly-sales/top-stores', payload)
      } else {
        payload.filters.retailer = state?.defaultID

        postStore('/v1/sales/monthly-sales/top-stores', payload)
      }
    }

    const getData = () => {
      getMonthlySales()
      getCategories()
      getBrands()
      getSKUs()
      getStoreSales()
    }

    const getGrowth = (current, ago) => {
      return FORMAT.toPercent(((current - ago) / ago) * 100)
    }

    onBeforeMount(() => {
      getData()

      watch(
        [
          dataMonthlySalesSummary,
          errorMonthlySalesSummary,
          loadingMonthlySalesSummary
        ],
        () => {
          if (loadingMonthlySalesSummary.value) {
            dashboardData.value = dashboardWidgets
          } else {
            if (
              dataMonthlySalesSummary?.value &&
              dataMonthlySalesSummary?.value?.length > 0
            ) {
              const data = dataMonthlySalesSummary?.value[0]

              dashboardData.value = [
                {
                  name: 'Monthly Sales',
                  value: FORMAT.toMetricNumber(data?.sales ?? 0),
                  subValue: `YA Same Month: ${FORMAT.toMetricNumber(
                    data?.sales_ya ?? 0
                  )}`,
                  subTitle: `Growth: ${getGrowth(
                    data?.sales ?? 0,
                    data?.sales_ya ?? 0
                  )}`,
                  arrow:
                    (data?.sales ?? 0) > (data?.sales_ya ?? 0) ? 'up' : 'down',
                  variant: 'primary'
                },
                {
                  name: 'Monthly Transactions',
                  value: FORMAT.toMetricNumber(data?.trx_count ?? 0),
                  subValue: `YA Same Month: ${FORMAT.toMetricNumber(
                    data?.trx_count_ya ?? 0
                  )}`,
                  subTitle: `Growth: ${getGrowth(
                    data?.trx_count ?? 0,
                    data?.trx_count_ya ?? 0
                  )}`,
                  arrow:
                    (data?.trx_count ?? 0) > data?.trx_count_ya ?? 0
                      ? 'up'
                      : 'down',
                  variant: 'primary'
                },
                {
                  name: 'Monthly Basket Size',
                  value: FORMAT.toMetricNumber(data?.basket_size ?? 0),
                  subValue: `YA Same Month: ${FORMAT.toMetricNumber(
                    data?.basket_size_ya ?? 0
                  )}`,
                  subTitle: `Growth: ${getGrowth(
                    data?.basket_size ?? 0,
                    data?.basket_size_ya ?? 0
                  )}`,
                  arrow:
                    (data?.basket_size ?? 0) > (data?.basket_size_ya ?? 0)
                      ? 'up'
                      : 'down',
                  variant: 'primary'
                },
                {
                  name: 'Monthly Frequency',
                  value: FORMAT.toMetricNumber(data?.frequency ?? 0),
                  subValue: `YA Same Month: ${FORMAT.toMetricNumber(
                    data?.frequency_ya ?? 0
                  )}`,
                  subTitle: `Growth: ${getGrowth(
                    data?.frequency ?? 0,
                    data?.frequency_ya ?? 0
                  )}`,
                  arrow:
                    (data?.frequency ?? 0) > (data?.frequency_ya ?? 0)
                      ? 'up'
                      : 'down',
                  variant: 'primary'
                },
                {
                  name: 'Unique Customers (P1)',
                  value: FORMAT.toMetricNumber(data?.customers_p1 ?? 0),
                  subValue: `YA Same Month: ${FORMAT.toMetricNumber(
                    data?.customers_p1_ya ?? 0
                  )}`,
                  subTitle: `Growth: ${getGrowth(
                    data?.customers_p1 ?? 0,
                    data?.customers_p1_ya ?? 0
                  )}`,
                  arrow:
                    (data?.customers_p1 ?? 0) > (data?.customers_p1_ya ?? 0)
                      ? 'up'
                      : 'down',
                  variant: 'primary'
                },
                {
                  name: 'Unique Customers (P3)',
                  value: FORMAT.toMetricNumber(data?.customers_p3 ?? 0),
                  subValue: `YA Same Month: ${FORMAT.toMetricNumber(
                    data?.customers_p3_ya ?? 0
                  )}`,
                  subTitle: `Growth: ${getGrowth(
                    data?.customers_p3 ?? 0,
                    data?.customers_p3_ya ?? 0
                  )}`,
                  arrow:
                    (data?.customers_p3 ?? 0) > (data?.customers_p3_ya ?? 0)
                      ? 'up'
                      : 'down',
                  variant: 'primary'
                },
                {
                  name: 'YTD Sales',
                  value: FORMAT.toMetricNumber(data?.sales_ytd ?? 0),
                  subValue: `YA: ${FORMAT.toMetricNumber(
                    data?.sales_ytd_ya ?? 0
                  )}`,
                  subTitle: `Growth: ${getGrowth(
                    data?.sales_ytd ?? 0,
                    data?.sales_ytd_ya ?? 0
                  )}`,
                  arrow:
                    (data?.sales_ytd ?? 0) > (data?.sales_ytd_ya ?? 0)
                      ? 'up'
                      : 'down',
                  variant: 'secondary'
                },
                {
                  name: 'YTD Transactions',
                  value: FORMAT.toMetricNumber(data?.trx_count_ytd ?? 0),
                  subValue: `YA: ${FORMAT.toMetricNumber(
                    data?.trx_count_ytd_ya ?? 0
                  )}`,
                  subTitle: `Growth: ${getGrowth(
                    data?.trx_count_ytd ?? 0,
                    data?.trx_count_ytd_ya ?? 0
                  )}`,
                  arrow:
                    (data?.trx_count_ytd ?? 0) > (data?.trx_count_ytd_ya ?? 0)
                      ? 'up'
                      : 'down',
                  variant: 'secondary'
                },
                {
                  name: 'YTD Basket Size',
                  value: FORMAT.toNumber(data?.basket_size_ytd ?? 0),
                  subValue: `YA: ${FORMAT.toNumber(
                    data?.basket_size_ytd_ya ?? 0
                  )}`,
                  subTitle: `Growth: ${getGrowth(
                    data?.basket_size_ytd ?? 0,
                    data?.basket_size_ytd_ya ?? 0
                  )}`,
                  arrow:
                    (data?.basket_size_ytd ?? 0) >
                    (data?.basket_size_ytd_ya ?? 0)
                      ? 'up'
                      : 'down',
                  variant: 'secondary'
                },
                {
                  name: 'YTD Frequency',
                  value: FORMAT.toNumber(data?.frequency_ytd ?? 0),
                  subValue: `YA: ${FORMAT.toNumber(
                    data?.frequency_ytd_ya ?? 0
                  )}`,
                  subTitle: `Growth: ${getGrowth(
                    data?.frequency_ytd ?? 0,
                    data?.frequency_ytd_ya ?? 0
                  )}`,
                  arrow:
                    (data?.frequency_ytd ?? 0) > (data?.frequency_ytd_ya ?? 0)
                      ? 'up'
                      : 'down',
                  variant: 'secondary'
                },
                {
                  name: 'YTD SKUs/Basket',
                  value: FORMAT.toNumber(data?.sku_ytd ?? 0),
                  subValue: `YA: ${FORMAT.toNumber(data?.sku_ytd_ya ?? 0)}`,
                  subTitle: `Growth: ${getGrowth(
                    data?.sku_ytd ?? 0,
                    data?.sku_ytd_ya ?? 0
                  )}`,
                  arrow:
                    (data?.sku_ytd ?? 0) > (data?.sku_ytd_ya ?? 0)
                      ? 'up'
                      : 'down',
                  variant: 'secondary'
                },
                {
                  name: 'YTD Unique Customers',
                  value: FORMAT.toMetricNumber(data?.customers_ytd ?? 0),
                  subValue: `YA: ${FORMAT.toMetricNumber(
                    data?.customers_ytd_ya ?? 0
                  )}`,
                  subTitle: `Growth: ${getGrowth(
                    data?.customers_ytd ?? 0,
                    data?.customers_ytd_ya ?? 0
                  )}`,
                  arrow:
                    (data?.customers_ytd ?? 0) > (data?.customers_ytd_ya ?? 0)
                      ? 'up'
                      : 'down',
                  variant: 'secondary'
                },
                {
                  name: 'Share of Sales',
                  value: FORMAT.toPercent(data?.percent_sales ?? 0),
                  subTitle: 'of total MySuki',
                  variant: 'info'
                },
                {
                  name: 'Share of Transactions',
                  value: FORMAT.toPercent(data?.percent_trx_count ?? 0),
                  subTitle: 'of total MySuki',
                  variant: 'info'
                },
                {
                  name: 'Share of Basket Size',
                  value: FORMAT.toPercent(data?.percent_basket_size ?? 0),
                  subTitle: 'of total MySuki',
                  variant: 'info'
                },
                {
                  name: 'Share of YTD SKUs/Basket',
                  value: FORMAT.toPercent(data?.percent_sku ?? 0),
                  subTitle: 'of total MySuki',
                  variant: 'info'
                },
                {
                  name: 'Share of Customers (P1)',
                  value: FORMAT.toPercent(data?.percent_customers_p1 ?? 0),
                  subTitle: 'of total MySuki',
                  variant: 'info'
                },
                {
                  name: 'Share of Customers (P3)',
                  value: FORMAT.toPercent(data?.percent_customers_p3 ?? 0),
                  subTitle: 'of total MySuki',
                  variant: 'info'
                }
              ]
            }
          }
        }
      )

      watch([dataMonthlySales, errorMonthlySales, loadingMonthlySales], () => {
        if (loadingMonthlySales.value) {
          monthlySalesData.value = null
          monthlyCustomerP30Data.value = null
          monthlyCustomerP90Data.value = null
          monthlyBasketSizeData.value = null
          monthlyFrequencyData.value = null
          monthlyBasketLinesData.value = null
        } else {
          if (errorMonthlySales?.value) {
            showToast(
              errorMonthlySales?.value?.message ??
                'There was a problem fetching sales.',
              'danger'
            )
          } else if (
            dataMonthlySales?.value &&
            dataMonthlySales?.value?.length > 0
          ) {
            const data = dataMonthlySales?.value

            monthlySalesData.value = data?.map(item => ({
              name: item?.month,
              value: FORMAT.toFixedNumber(item?.sales ?? 0),
              value2: FORMAT.toFixedNumber(item?.sales_ya ?? 0)
            }))

            monthlyCustomerP30Data.value = data?.map(item => ({
              name: item?.month,
              value: FORMAT.toFixedNumber(item?.customers_p1 ?? 0),
              value2: FORMAT.toFixedNumber(item?.customers_p1_ya ?? 0)
            }))

            monthlyCustomerP90Data.value = data?.map(item => ({
              name: item?.month,
              value: FORMAT.toFixedNumber(item?.customers_p3 ?? 0),
              value2: FORMAT.toFixedNumber(item?.customers_p3_ya ?? 0)
            }))

            monthlyBasketSizeData.value = data?.map(item => ({
              name: item?.month,
              value: FORMAT.toFixedNumber(item?.basket_size ?? 0),
              value2: FORMAT.toFixedNumber(item?.basket_size_ya ?? 0)
            }))

            monthlyFrequencyData.value = data?.map(item => ({
              name: item?.month,
              value: FORMAT.toFixedNumber(item?.frequency ?? 0),
              value2: FORMAT.toFixedNumber(item?.frequency_ya ?? 0)
            }))

            monthlyBasketLinesData.value = data?.map(item => ({
              name: item?.month,
              value: FORMAT.toFixedNumber(item?.basket_size_items ?? 0),
              value2: FORMAT.toFixedNumber(item?.basket_size_items_ya ?? 0)
            }))
          }
        }
      })

      watch([dataCategory, errorCategory, loadingCategory], () => {
        if (loadingCategory.value) {
          categoryData.value = null
        } else {
          if (errorCategory?.value) {
            showToast(
              errorCategory?.value?.message ??
                'There was a problem fetching categories.',
              'danger'
            )
          } else if (dataCategory?.value && dataCategory?.value?.length > 0) {
            categoryData.value = dataCategory?.value?.map(item => ({
              name: item?.category_name,
              value: FORMAT.toFixedNumber(item?.sales ?? 0)
            }))
          }
        }
      })

      watch([dataBrand, errorBrand, loadingBrand], () => {
        if (loadingBrand.value) {
          brandData.value = null
        } else {
          if (errorBrand?.value) {
            showToast(
              errorBrand?.value?.message ??
                'There was a problem fetching brands.',
              'danger'
            )
          } else if (dataBrand?.value && dataBrand?.value?.length > 0) {
            brandData.value = dataBrand?.value?.map(item => ({
              name: item?.brand_name,
              value: FORMAT.toFixedNumber(item?.sales ?? 0)
            }))
          }
        }
      })

      watch([dataSKU, errorSKU, loadingSKU], () => {
        if (loadingSKU.value) {
          SKUData.value = null
        } else {
          if (errorSKU?.value) {
            showToast(
              errorSKU?.value?.message ?? 'There was a problem fetching skus.',
              'danger'
            )
          } else if (dataSKU?.value && dataSKU?.value?.length > 0) {
            SKUData.value = dataSKU?.value?.map(item => ({
              name: item?.sku_desc,
              value: FORMAT.toFixedNumber(item?.sales ?? 0)
            }))
          }
        }
      })

      watch([dataStore, errorStore, loadingStore], () => {
        if (loadingStore.value) {
          storeData.value = null
        } else {
          if (dataStore?.value && dataStore?.value?.length > 0) {
            storeData.value = dataStore?.value?.map(item => ({
              name: item?.store_name,
              value: FORMAT.toFixedNumber(item?.sales ?? 0)
            }))
          }
        }
      })
    })

    return {
      HELP,
      VALIDATION,
      getIconUrl,
      showModal,
      filterForm,
      isLoading,
      monthYear,
      dashboardData,
      monthlySalesData,
      monthlyCustomerP30Data,
      monthlyCustomerP90Data,
      monthlyBasketSizeData,
      monthlyFrequencyData,
      monthlyBasketLinesData,
      categoryData,
      brandData,
      SKUData,
      storeData,
      loadingMonthlySales,
      loadingMonthlySalesSummary,
      loadingCategory,
      loadingBrand,
      loadingSKU,
      loadingStore,
      onToggleModal,
      onSelectDate,
      onFilter,
      exportTable
    }
  }
}
</script>
